import React from "react";
import '../css/AtomAnimation.css';

const AtomAnimation = () => {
  return (
    <div className="atom">
      <div className="line line-1">
        <div className="electron"></div>
      </div>
      <div className="line line-2">
        <div className="electron"></div>
      </div>
      <div className="line line-3">
        <div className="electron"></div>
      </div>
    </div>
  );
};

export default AtomAnimation;
