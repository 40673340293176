import "./css/App.css";
import SaaisAnimation from "./components/SaaisAnimation";
import ComingSoonAnimation from "./components/ComingSoonAnimation";
import BackgroundAnimation from "./components/BackgroundAnimation";
import AtomAnimation from "./components/AtomAnimation";

function App() {
  
  return (
    <div className="App" style={{height:"100vh",width:"100vw"}}>
    
      <div>
        <BackgroundAnimation/>
      </div>

      <div className="atom-outer">
        <AtomAnimation/>
      </div> 

      <div className="Saais-div">
        <SaaisAnimation/>
      </div>

      <div className="text-outer">
        <ComingSoonAnimation />
      </div>
    </div>
  );
}

export default App;
