import React, { useEffect } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';
import '../css/BackgroundAnimation.css';

const BackgroundAnimation = () => {
  useEffect(() => {
    const style = ['style1', 'style2', 'style3', 'style4'];
    let numeroAleatorio = 5000;

    const getRandomArbitrary = (min, max) =>
      Math.floor(Math.random() * (max - min)) + min;

    const carregarMeteoro = () => {
      setTimeout(carregarMeteoro, numeroAleatorio);
      numeroAleatorio = getRandomArbitrary(5000, 10000);
      const meteoro = `<div class='meteoro ${
        style[getRandomArbitrary(0, 4)]
      }'></div>`;
      document.getElementsByClassName('chuvaMeteoro')[0].innerHTML = meteoro;
      setTimeout(() => {
        document.getElementsByClassName('chuvaMeteoro')[0].innerHTML = '';
      }, 1000);
    };

    carregarMeteoro();

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      100,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    const dista = Math.min(300, window.innerWidth);
    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    for (let i = 0; i < 1600; i++) {
      const theta = Math.acos(THREE.MathUtils.randFloatSpread(2));
      const phi = THREE.MathUtils.randFloatSpread(360);

      const x = dista * Math.sin(theta) * Math.cos(phi);
      const y = dista * Math.sin(theta) * Math.sin(phi);
      const z = dista * Math.cos(theta);

      vertices.push(x, y, z);
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    const material = new THREE.PointsMaterial({

    });

    const parti = new THREE.Points(geometry, material);
    parti.boundingSphere = new THREE.Sphere(new THREE.Vector3(), 5);

    const renderingParent = new THREE.Group();
    renderingParent.add(parti);
    const resizeContainer = new THREE.Group();
    resizeContainer.add(renderingParent);
    scene.add(resizeContainer);

    camera.position.z = 360;

    let mouseX;
    let mouseY;
    let myTween;

    const onMouseMove = (event) => {
      if (myTween) myTween.kill();

      mouseX = (event.clientX / window.innerWidth) * 2 - 1;
      mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
      myTween = gsap.to(parti.rotation, {
        duration: 0.1,
        x: mouseY * -1,
        y: mouseX,
      });
    };

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    const animProps = { scale: 1, xRot: 0, yRot: 0 };

    gsap.to(animProps, {
      duration: 16,
      scale: 1.5,
      repeat: -1,
      yoyo: true,
      ease: 'sine',
      onUpdate: () => {
        renderingParent.scale.set(
          animProps.scale,
          animProps.scale,
          animProps.scale
        );
      },
    });

    gsap.to(animProps, {
      duration: 1000,
      xRot: Math.PI * 2,
      yRot: Math.PI * 4,
      repeat: -1,
      yoyo: true,
      ease: 'none',
      onUpdate: () => {
        renderingParent.rotation.set(animProps.xRot, animProps.yRot, 0);
      },
    });

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    window.addEventListener('resize', () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    document.addEventListener('mousemove', onMouseMove, false);

    animate();

    return () => {
      renderer.dispose();
    };
  }, []);

  return(
    <div className="space stars1">
        <div className="main"></div>
        <div className="chuvaMeteoro"></div>
  </div>
  );
};

export default BackgroundAnimation;
